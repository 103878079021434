import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    redirect: "/layout/home",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login/login.vue"),
  },
  {
    path: "/layout",
    redirect: "/layout/home",
    name: "layout",
    component: () => import("../views/layout/layout.vue"),
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("../views/layout/home.vue"),
      },
      {
        path: "shop",
        name: "shop",
        component: () => import("../views/layout/shop.vue"),
      },
      {
        path: "user",
        name: "user",
        component: () => import("../views/layout/user.vue"),
      },
    ],
  },
  {
    path: "/pages",
    name: "pages",
    redirect: "/pages/friends",
    component: () => import("../views/layout/bar.vue"),
    children: [
      {
        path: "friends",
        name: "friends",
        meta: {
          title: "我的伙伴",
        },
        component: () =>
          import(
            /* webpackChunkName: "homepages" */ "../views/homePages/friends.vue"
          ),
      },
      {
        path: "merchants",
        name: "merchants",
        meta: {
          title: "我的商户",
        },
        component: () =>
          import(
            /* webpackChunkName: "homepages" */ "../views/homePages/merchants.vue"
          ),
      },
      {
        path: "ranking",
        name: "ranking",
        meta: {
          title: "达标牛人",
        },
        component: () =>
          import(
            /* webpackChunkName: "homepages" */ "../views/homePages/ranking.vue"
          ),
      },
      {
        path: "machinery",
        name: "machinery",
        meta: {
          title: "我的机具",
        },
        component: () =>
          import(
            /* webpackChunkName: "homepages" */ "../views/homePages/machinery.vue"
          ),
      },
      {
        path: "nowBan",
        name: "nowBan",
        meta: {
          title: "今日收益明细",
        },
        component: () =>
          import(
            /* webpackChunkName: "homepages" */ "../views/homePages/nowBan.vue"
          ),
      },
      {
        path: "activity",
        name: "activity",
        meta: {
          title: "活动消息",
        },
        component: () =>
          import(
            /* webpackChunkName: "homepages" */ "../views/homePages/activity.vue"
          ),
      },
      {
        path: "share",
        name: "share",
        meta: {
          title: "分享伙伴",
        },
        component: () =>
          import(
            /* webpackChunkName: "homepages" */ "../views/homePages/share.vue"
          ),
      },
      {
        path: "capital",
        name: "capital",
        meta: {
          title: "分润提现",
        },
        component: () =>
          import(
            /* webpackChunkName: "userpages" */ "../views/userPages/capital.vue"
          ),
      },
      {
        path: "account",
        name: "account",
        meta: {
          title: "收益提现",
        },
        component: () =>
          import(
            /* webpackChunkName: "userpages" */ "../views/userPages/account.vue"
          ),
      },
      {
        path: "achievement",
        name: "achievement",
        meta: {
          title: "我的段位",
        },
        component: () =>
          import(
            /* webpackChunkName: "userpages" */ "../views/userPages/achievement.vue"
          ),
      },
      {
        path: "balance",
        name: "balance",
        meta: {
          title: "收益明细",
        },
        component: () =>
          import(
            /* webpackChunkName: "userpages" */ "../views/userPages/balance.vue"
          ),
      },
      {
        path: "about",
        name: "about",
        meta: {
          title: "关于我们",
        },
        component: () =>
          import(
            /* webpackChunkName: "userpages" */ "../views/userPages/aboutus.vue"
          ),
      },
      {
        path: "password",
        name: "password",
        meta: {
          title: "修改密码",
        },
        component: () =>
          import(
            /* webpackChunkName: "userpages" */ "../views/userPages/password.vue"
          ),
      },
      {
        path: "callus",
        name: "callus",
        meta: {
          title: "联系我们",
        },
        component: () =>
          import(
            /* webpackChunkName: "userpages" */ "../views/userPages/callus.vue"
          ),
      },
      {
        path: "management",
        name: "management",
        meta: {
          title: "账户管理",
        },
        component: () =>
          import(
            /* webpackChunkName:"userpages" */ "../views/userPages/management.vue"
          ),
      },
      {
        path: "addaccount",
        name: "addaccount",
        meta: {
          title: "管理账户",
        },
        component: () =>
          import(
            /* webpackChunkName:"userpages" */ "../views/userPages/addAccount.vue"
          ),
      },
      {
        path: "accountstatus",
        name: "accountStatus",
        meta: {
          title: "提现状态",
        },
        component: () =>
          import(
            /* webpackChunkName:"userpages" */ "../views/userPages/accountStatus.vue"
          ),
      },
      {
        path: "shopUser",
        name: "shopUser1",
        meta: {
          title: "个人中心",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/shopUser.vue"
          ),
      },
      {
        path: "signIn",
        name: "signIn",
        meta: {
          title: "签到",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopPages" */ "../views/shopPages/signIn.vue"
          ),
      },
      {
        path: "shopDetail",
        name: "shopDetail",
        meta: {
          title: "商品详情",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopPages" */ "../views/shopPages/shopDetails.vue"
          ),
      },
      {
        path: "hotActivities",
        name: "hotActivities",
        meta: {
          title: "火爆活动",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopPages" */ "../views/shopPages/hotActivities.vue"
          ),
      },
      {
        path: "payment",
        name: "payment",
        meta: {
          title: "支付产品",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopPages" */ "../views/shopPages/payment.vue"
          ),
      },
      {
        path: "rural",
        name: "rural",
        meta: {
          title: "乡村振兴",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopPages" */ "../views/shopPages/rural.vue"
          ),
      },
      {
        path: "luckyList",
        name: "luckyList",
        meta: {
          title: "转运榜",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopPages" */ "../views/shopPages/luckyList.vue"
          ),
      },
      {
        path: "allCategories",
        name: "allCategories",
        meta: {
          title: "全部分类",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopPages" */ "../views/shopPages/allCategories.vue"
          ),
      },
      {
        path: "buy",
        name: "buy",
        meta: {
          title: "发起订单",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopPages" */ "../views/shopPages/buy.vue"
          ),
      },
      {
        path: "finish",
        name: "finish",
        meta: {
          title: "订单完成",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopPages" */ "../views/shopPages/finish.vue"
          ),
      },
      {
        path: "before",
        name: "before",
        meta: {
          title: "火拼成员",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopPages" */ "../views/shopPages/before.vue"
          ),
      },
      {
        path: "addressPage",
        name: "addressPage",
        meta: {
          title: "我的地址",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/addressPage.vue"
          ),
      },
      {
        path: "allOrders",
        name: "allOrders",
        meta: {
          title: "全部火拼",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/allOrders.vue"
          ),
      },
      {
        path: "group",
        name: "group",
        meta: {
          title: "火拼中",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/group.vue"
          ),
      },
      {
        path: "tosent",
        name: "tosent",
        meta: {
          title: "已拼中",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/tosent.vue"
          ),
      },
      {
        path: "toReceived",
        name: "toReceiver",
        meta: {
          title: "待收货",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/toReceived.vue"
          ),
      },
      {
        path: "integral",
        name: "integral",
        meta: {
          title: "我的积分",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/integral.vue"
          ),
      },
      {
        path: "muLike",
        name: "muLike",
        meta: {
          title: "我的收藏",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/muLike.vue"
          ),
      },
      {
        path: "myBalance",
        name: "myBalance",
        meta: {
          title: "我的账户",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/myBalance.vue"
          ),
      },
      {
        path: "rules",
        name: "rules",
        meta: {
          title: "火拼攻略",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/rules.vue"
          ),
      },
      {
        path: "withdrawal",
        name: "withdrawal",
        meta: {
          title: "提现",
        },
        component: () =>
          import(
            /* webpackChunkName:"shopUserPages" */ "../views/shopUserPages/Withdrawal.vue"
          ),
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    redirect: "/admin/machine",
    component: () =>
      import(/* webpackChunkName:"admin" */ "../views/admin/adminTab.vue"),
    children: [
      {
        path: "machine",
        name: "machine",
        component: () =>
          import(/* webpackChunkName:"admin" */ "../views/admin/Machine.vue"),
      },
      {
        path: "equipment",
        name: "equipment",
        component: () =>
          import(/* webpackChunkName:"admin" */ "../views/admin/equipment.vue"),
      },
      {
        path: "merchant",
        name: "merchant",
        component: () =>
          import(/*webpackChunkName:"admin" */ "../views/admin/merchant.vue"),
      },
      {
        path: "user",
        name: "adminUser",
        component: () =>
          import(/* webpackChunkName:"admin" */ "../views/admin/user.vue"),
      },
      {
        path: "profit",
        name: "profit",
        component: () =>
          import(/* webpackChunkName:"admin" */ "../views/admin/profit.vue"),
      },
      {
        path: "shopping",
        name: "shopping",
        component: () =>
          import(/* webpackChunkName:"admin" */ "../views/admin/shopping.vue"),
      },
      {
        path: "market",
        name: "market",
        component: () =>
          import(/* webpackChunkName:"admin" */ "../views/admin/market.vue"),
      },
      {
        path: "orderList",
        name: "orderList",
        component: () =>
          import(/* webpackChunkName:"admin" */ "../views/admin/orderList.vue"),
      },
    ],
  },
  {
    path: "/admin/login",
    name: "adminLogin",
    component: () =>
      import(/* webpackChunkName:'admin' */ "../views/admin/adminLogin.vue"),
  },
  {
    path: "/404",
    component: () => import("../views/404/404page.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");
  if (to.path == "/login") {
    token ? next("/layout/home") : next();
  } else if (to.path.includes("admin")) {
    const tokenA = localStorage.getItem("tokenA");
    if (to.path == "/admin/login") {
      tokenA ? next("/admin/machine") : next();
    } else {
      tokenA ? next() : next("/admin/login");
    }
  } else if (token) {
    let idCard = localStorage.getItem("idCard");
    if (to.path != "/layout/home") {
      idCard == "true" ? next() : next("/layout/home");
    } else {
      next();
    }
  } else {
    next("/login"); //不去登陆页，没有登录
  }
});
export default router;
